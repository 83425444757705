@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-BoldOblique.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-DemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-DemiBoldOblique.otf')
    format('opentype');
  font-weight: 600;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-ExtraBoldOblique.otf')
    format('opentype');
  font-weight: 800;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-HeavyOblique.otf') format('opentype');
  font-weight: 900;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-LightOblique.otf') format('opentype');
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-MediumOblique.otf') format('opentype');
  font-weight: 500;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-RegularOblique.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('stylesheets/fonts/VanguardCF-ThinOblique.otf') format('opentype');
  font-weight: 100;
  font-style: oblique;
}
