@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Store Typography */
  .text-h1 {
    @apply font-vanguard text-[56px] leading-[56px] tablet:text-[86px] tablet:leading-[86px] font-bold text-center uppercase text-black-charcoal;
  }
  .text-header {
    @apply font-inter text-[22px] font-bold text-black-charcoal;
  }
  .text-h2 {
    @apply font-inter font-bold text-[22px] leading-[27px] tablet:text-[26px] tablet:leading-[32px];
  }
  .text-body {
    @apply font-inter text-[14px] leading-[19px] tablet:text-[19px] tablet:leading-[26px] text-store-mediumgray;
  }
  .text-body-bold {
    @apply font-inter font-bold text-[14px] leading-[19px] tablet:text-[19px] tablet:leading-[26px];
  }
  .text-xs-body {
    @apply font-inter text-[12px] leading-[15px] tablet:text-[16px] tablet:leading-[20px];
  }
  .text-xs-body-bold {
    @apply font-inter font-bold text-[12px] leading-[15px] tablet:text-[16px] tablet:leading-[20px];
  }
  .error-text {
    @apply font-inter text-[12px] font-normal leading-[15px] text-left text-store-error;
  }
  /* Member's Portal Typography */
  .mb-text-body {
    @apply font-inter text-[13px] tablet:text-[16px] leading-[22px] text-black;
  }
}

.store-link,
a {
  @apply font-inter text-[12px] leading-[15px] tablet:text-[14px] tablet:leading-[19px] hover:text-store-primary transition-all;
}
